angular.module('eOpti.app.eShop').config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('app.eShop', {
        abstract: true,
        url: '/eShop',
        template: '<ui-view/>'
    })

    $stateProvider.state('app.eShop.migrate', {
        url: '/migrate',
        template: '<opti-e-shop-migrate></opti-e-shop-migrate>',
        controller: ['breadcrumb', function(breadcrumb) {
            breadcrumb.data = [{
                name: 'default.breadcrumb.OTHERS'
            }, {
                name: 'eShop.breadcrumb.HEADING'
            }, {
                name: 'eShop.breadcrumb.MIGRATE'
            }]
        }]
    })

    $stateProvider.state('app.eShop.orders', {
        abstract: true,
        url: '/orders',
        template: '<ui-view/>'
    })

    let statuses = ['pending', 'pending_payment', 'holded', 'pending_ok', 'processing', 'processing_ready_for_shipment',
        'complete', 'closed', 'canceled', 'all']

    statuses.forEach(status => {
        $stateProvider.state('app.eShop.orders.' + status, {
            url: '/' + status + '/:page/:search',
            params: {
                page: '1',
                pageSize: '10',
                sortBy: 'id',
                sortOrder: 'desc',
                search: {
                    array: true
                },
                columns: {
                    array: true
                }
            },
            template: '<base-table end-point="api/e-shop/orders/table/' + status + '" ' +
                'header="eShop.order.breadcrumb.ORDERS" sub-header="eShop.order.status.' + status.toUpperCase()  + '" ' +
                'ref="app.eShop.orders.' + status + '"></base-table>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'default.breadcrumb.OTHERS'
                }, {
                    name: 'eShop.breadcrumb.HEADING'
                }, {
                    name: 'eShop.order.breadcrumb.ORDERS'
                }, {
                    functions: 'eShopOrders',
                    args: 'eShop.order.status.' + status.toUpperCase()
                }]
            }]
        })
    })

    $stateProvider.state('app.eShop.order', {
        url: '/order/:id',
        template: '<opti-e-shop-order-details v-on-status-name-was-changed="statusNameWasChanged"></opti-e-shop-order-details>',
        controller: ['$scope', '$state', 'breadcrumb', function($scope, $state, breadcrumb) {
            breadcrumb.data = [{
                name: 'default.breadcrumb.OTHERS'
            }, {
                name: 'eShop.breadcrumb.HEADING'
            }, {
                name: 'eShop.order.breadcrumb.ORDERS'
            }, {
                functions: 'eShopOrders',
                args: null
            }, {
                name: 'eShop.order.breadcrumb.ORDER',
                id: $state.params.id
            }]

            $scope.statusNameWasChanged = statusName => {
                breadcrumb.data[3].args = statusName
            }
        }]
    })

    $stateProvider.state('app.eShop.customers', {
        url: '/customers/:page/:search',
        params: {
            page: '1',
            pageSize: '10',
            sortBy: 'id',
            sortOrder: 'desc',
            search: {
                array: true
            },
            columns: {
                array: true
            }
        },
        template: '<base-table end-point="api/e-shop/customers/table" ' +
            'header="eShop.customer.breadcrumb.CUSTOMERS" ref="app.eShop.customers"></base-table>',
        controller: ['breadcrumb', function(breadcrumb) {
            breadcrumb.data = [{
                name: 'default.breadcrumb.OTHERS'
            }, {
                name: 'eShop.breadcrumb.HEADING'
            }, {
                functions: 'eShopCustomers',
                args: [null]
            }]
        }]
    })

    .state('app.eShop.customer', {
        url: '/customer/:id',
        template: '<opti-e-shop-customer-details></opti-e-shop-customer-details>',
        controller: ['$state', 'breadcrumb', function($state, breadcrumb) {
            breadcrumb.data = [{
                name: 'default.breadcrumb.OTHERS'
            }, {
                name: 'eShop.breadcrumb.HEADING'
            }, {
                functions: 'eShopCustomers',
                args: ['state']
            }, {
                name: 'eShop.customer.breadcrumb.CUSTOMER',
                id: $state.params.id
            }]
        }]
    })
}])